import React from 'react';
import { Helmet } from 'react-helmet'; /** pour intégrer des elements entete Html */
import BoxedWrapper from './components/BoxedWrapper/BoxedWrapper';
import RowCta from './components/RowCta/RowCta';



const Carte = () => {
  return (
    <>
    <Helmet>
         <title>La qualité - La piazzetta - Restaurant Italien</title>
        <meta name="description" content="Votre pizzeria à Emporter au kochersberg."></meta>
      </Helmet>
      <BoxedWrapper>
      <h1>Notre philosophie de travail, la Qualité !</h1>
      A La Piazzetta, nous sommes fiers de partager notre passion pour la cuisine avec nos clients. Notre carte est composée principalement de produits frais et tous nos plats sont préparés à partir de recettes maison.
        <br /><br />
      Nous sommes situés à Schnersheim, et offrons un service à emporter et de livraison pour que vous puissiez profiter de nos plats où que vous soyez.
        <br /><br />
      Depuis toujours nous nous efforçons de fournir à nos clients une cuisine authentique et savoureuse, et nous travaillons en étroite collaboration avec un fournisseur italien et des fournisseurs locaux pour garantir la qualité de nos produits.
        <br /><br />
        
      </BoxedWrapper>
      <RowCta />
    </>
  );
};

export default Carte;

import React from 'react';

import logo from './../../piazzetta.jpeg';
import './FooterWrapper.css';

function MenuTop() {
  return (
    <div className="footerWrapper">
      <div className="footer1">
        <img src={logo} alt="Piazzetta" className='logo'/>
        <br/><br/>
        <b>La Piazzetta</b>
        <p>
            1 Rue de l'Epeautre, 67370 Schnersheim, France
        </p>
      </div>
      <div className="footer2 numTel">
      03 88 75 68 40
      </div>
    </div>

  );
}

export default MenuTop;

import React from 'react';
import './Ban.css';

// import banImg from '../../assets/lapiazzetta.jpg';

function MenuTop() {
  return (
    <div className="banWrapper">
     <div className="accroche">
     <h1>Benvenuti a tutti !</h1>
        A La Piazzetta, nous offrons une cuisine italienne authentique, nos pizzas napolitaines garnies de produits choisis avec soin, nos burgers aux saveurs de la péninsule, nos desserts et glaces selon les saisons, nos vins et bières de la botte.<br /><br />

        Notre service de livraison dans le Kochersberg (32 communes) est garantie par nos soins.<br /><br />
        Vous pouvez commander sur place ou à emporter au <strong>03 88 75 68 40</strong><br /><br />
        <strong>TOUS LES JOURS SAUF LE MARDI de 17H30 à 21H30</strong>
     </div>
    </div>
  );
}

export default MenuTop;
